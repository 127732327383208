import './App.css';
import Home from "./Pages/Home"

function App() {
  return (
    <div className="App  max-w-screen-desktop mx-auto">
      <Home />
    </div>
  );
}

export default App;
